//ヘッダー
$(function() {
    var headerH = $("#header").outerHeight(true);
    function fixedAnime() {
        var scroll = $(window).scrollTop();
        if (scroll >= headerH){
            $('#header').addClass('header__scroll');
        }else{
            $('#header').removeClass('header__scroll');
        }    
    }

    $(window).scroll(function () {
        fixedAnime();
    });

    $(window).on('load', function () {
        fixedAnime();
    });
});

// ハンバーガーメニュー
$(function() {
    'use strict';

    var $hamburgerBtn = $('#js-menu');
    var $hamburgerMenu = $('#js-drawer');

    $hamburgerBtn.on('click', function() {
        $('body').stop().toggleClass('menu-active');
        $hamburgerMenu.slideToggle(300);
        $('#mypage').toggleClass('active');
        if ($(this).attr('aria-expanded') == 'false') {
            $(this).attr('aria-expanded', true);
        } else {
            $(this).attr('aria-expanded', false);
        }
    });
    $(window).keydown(function(event) {
        if (event.key === "Escape"){
           $('body').removeClass('menu-active');
           $hamburgerMenu.slideUp(300);
        }
    });
});


// スムーススクロール
$(function(){
    $('a[href*="#"]').click(function() {
        var elmHash = $(this).attr('href');
        var pos = $(elmHash).offset().top-88;
        $('body,html').animate({scrollTop: pos}, 500);
        return false;
    });
});

// FAQアコーディオン
$(function(){
    $(".accordion dt").on("click", function() {
    $(this).next().slideToggle();
    $(this).children('button').toggleClass("open");
    });
});

//closeボタン

$(function(){
    $(".close").on("click", function(){
        $(this).parent().fadeOut(200);
    });
});

//買取フォーム　銀行選択肢
$(window).on('load',function(){
    //エラー画面
    $(function() {
        if($('[name="bank-type"]:eq(0)').prop('checked')){
            $('input[name="bank-type"]:eq(0)').attr('checked', true).prop('checked', true);
            $('input[name="bank-type"]:eq(1)').attr('checked', false).prop('checked', false);
            $('.bank-yucho').fadeOut();
            $('.bank-bank').fadeIn();
        } else if($('[name="bank-type"]:eq(1)').prop('checked')){
            $('input[name="bank-type"]:eq(1)').attr('checked', true).prop('checked', true);
            $('input[name="bank-type"]:eq(0)').attr('checked', false).prop('checked', false);
            $('.bank-bank').fadeOut();
            $('.bank-yucho').fadeIn();
        }
    });
    //入力画面
    $(function() {
        $('[name="bank-type"]').click(function() {
            if($('[name="bank-type"]:eq(0)').prop('checked')){
                $('input[name="bank-type"]:eq(0)').attr('checked', true).prop('checked', true);
                $('input[name="bank-type"]:eq(1)').attr('checked', false).prop('checked', false);
                $('.bank-yucho').fadeOut();
                $('.bank-bank').fadeIn();
            } else {
                $('input[name="bank-type"]:eq(1)').attr('checked', true).prop('checked', true);
                $('input[name="bank-type"]:eq(0)').attr('checked', false).prop('checked', false);
                $('.bank-bank').fadeOut();
                $('.bank-yucho').fadeIn();
            }
        });
    });
    //確認画面
    $(function() {
        if($('.mw_wp_form_confirm input[value="銀行"]:eq(0)').prop('checked')){
            $('input[name="bank-type"]:eq(0)').attr('checked', true).prop('checked', true);
            $('input[name="bank-type"]:eq(1)').attr('checked', false).prop('checked', false);
            $('.bank-yucho').fadeOut();
            $('.bank-bank').fadeIn();
        } else if($('.mw_wp_form_confirm input[name="bank-type"]:eq(1)').prop('checked')){
            $('input[name="bank-type"]:eq(1)').attr('checked', true).prop('checked', true);
            $('input[name="bank-type"]:eq(0)').attr('checked', false).prop('checked', false);
            $('.bank-bank').fadeOut();
            $('.bank-yucho').fadeIn();
        }
    });
    $(function(){
        if($('.mw_wp_form_confirm input[value="銀行"]').length){
            $('.bank-bank').show();
            $('.bank-yucho').hide();
        }
        else if($('.mw_wp_form_confirm input[value="ゆうちょ銀行"]').length){
            $('.bank-bank').hide();
            $('.bank-yucho').show();
        }
    });
});
//太陽光買取フォーム　銀行セレクト
$(function(){
    $('.bank__radio span:first-of-type label').on('click', function() {
        $(this).addClass('label-active');
        $('.bank__radio span:last-of-type label').removeClass();
    });
    $('.bank__radio span:last-of-type label').on('click', function() {
        $(this).addClass('label-active');
        $('.bank__radio span:first-of-type label').removeClass();
    });
});